body {
    $border-radius: 10px;

    &:not([dir="rtl"]) {
        [type="file"].form-control {
            &.is-invalid,
            &.ng-invalid {
                padding-left: calc(0.3rem) !important;
            }
        }

        input.form-control:not([type="file"]) {
            padding-left: calc(0.75rem) !important;
            padding-right: calc(0.75rem) !important;
        }

        .input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
            margin-left: -2px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
        .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3) {
            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
        }

        .invalid-feedback {
            display: block;
        }

        @media screen and (max-width: 1023px) {
            input.form-control[formcontrolname="search"] {
                padding-left: calc(0.75rem) !important;
                border-right: 0;
            }
        }
    }

    &:has([dir="rtl"]) {
        [type="file"].form-control {
            &.is-invalid,
            &.ng-invalid {
                padding-right: calc(0.3rem) !important;
            }
        }

        input.form-control:not([type="file"]) {
            padding-right: calc(0.75rem) !important;
        }
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }


    .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
        margin-right: -1px;
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }

    .invalid-feedback {
        display: block !important;
    }
}
