.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);

    &.business__sign_up {
        display: block !important;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 1rem;
        border-bottom: 1px solid #dee2e6;
        border-top-left-radius: calc(0.3rem - 1px);
        border-top-right-radius: calc(0.3rem - 1px);
    }

    .btn-close {
        padding: ($modal-header-padding-y * .5) ($modal-header-padding-x * .5) !important;
        margin: ($modal-header-padding-y * -.5) ($modal-header-padding-x * -.5) ($modal-header-padding-y * -.5) auto !important;

        [dir="rtl"] & {
            padding: ($modal-header-padding-y * .5) ($modal-header-padding-x * .5) !important;
            margin: ($modal-header-padding-y * -.5) auto ($modal-header-padding-x * -.5) ($modal-header-padding-y * -.5) !important;
        }

        &:focus {
            box-shadow: unset !important;
        }
    }
}

;

.modal-scroll {
    overflow: auto;
}

.modal-body {
    -webkit-overflow-scrolling: touch;
}

.modal-content{
    border-radius: 10px !important;
    overflow: hidden !important;
}

body:not([dir="rtl"]) {
    .modal-xl>.modal-content {
        @media screen and (min-width: 1200px) {
            margin-left: 220px;
            width: calc(100% - 220px) !important;
        }

        @media screen and (min-width: 1023px) {
            margin-left: 170px;
            width: calc(100% - 110px) !important;
        }
    }
}

[dir="rtl"] {
    .modal {
        position: fixed;
        top: 0;
        right: 0;
        left: unset;
        z-index: 1060;
        display: none;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        outline: 0;
    }

    .modal-xl>.modal-content {
        @media screen and (min-width: 1200px) {
            margin-right: 220px;
            width: calc(100% - 220px) !important;
        }

        @media screen and (min-width: 1023px) {
            margin-right: 170px;
            width: calc(100% - 110px) !important;
        }
    }
}

.max-width-300px .modal-dialog{
    max-width: 300px !important;
    margin-inline: auto;
}
