.rtl {
    $border-radius: 10px;

    .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
    .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }

    .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
    }

    // Card-store
    .card__store__title {
        p {
            margin-right: -2rem !important;
            margin-left: unset !important;
        }

        small {
            margin-left: 2rem !important;
        }
    }

    .cart__sheet {
        left: -3rem !important;
        right: unset !important;
    }

    // Activation-icon
    .checkbox-container {
        .checbox-interface:after {
            left: 3px !important;
            right: unset !important;
        }

        .off {
            text-align: right !important;
            right: 0 !important;
        }

        .on {
            text-align: left !important;
            right: 0 !important;
        }

        input[type="checkbox"]:checked+.dummy-bg {
            right: 0 !important;
        }

        input[type="checkbox"]:checked~.checbox-interface {
            &:after {
                left: 1.8rem !important;
                right: 2px !important;

                .custom_toggle & {
                    left: 1.6rem !important;
                    right: unset !important;
                }
            }
        }
    }

    .border_start {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-top-right-radius: $border-radius !important;
        border-bottom-right-radius: $border-radius !important;
    }

    .border_end {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-top-left-radius: $border-radius !important;
        border-bottom-left-radius: $border-radius !important;
    }

    .input-group-color {
        width: 50px !important;
        height: 25px !important;
        left: 1.5rem !important;
        right: unset !important;
        margin-top: -3px;
    }

    .checkbox-container.students .checbox-interface:after {
        content: "";
        right: 1.8rem !important;
    }
    
    .checkbox-container {
        &.custom_toggle input[type="checkbox"]:checked~.checbox-interface {
            &:after {
                left: 1.6rem !important;
                right: unset !important;
            }
        }

        &.custom_toggle_small input[type="checkbox"]:checked~.checbox-interface {
            &:after {
                left: 1rem !important;
                right: unset !important;
            }
        }
    }

    .checkbox-container {
        &.custom_toggle .checbox-interface:after {
            content: "";
            right: 1.6rem !important;
        }
        
        &.custom_toggle_small .checbox-interface:after {
            content: "";
            right: 1rem !important;
        }
    }

    // multiselect-dropdown
    .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
        line-height: 16px;
        display: block;
        position: absolute;
        box-sizing: border-box;
        width: 40px;
        height: 38px;
        right: unset !important;
        left: 0 !important;    
        top: 0;
        padding: 4px 8px;
        margin: 0;
        text-decoration: none;
        text-align: center;
        cursor: pointer;
        transition: transform .2s;
    }

    .dropdown-list li {
        padding: 6px 10px;
        cursor: pointer;
        text-align: right !important;
    }

    .form-check-input {
        float: right !important;
    }

    .sfm.btn-outline-gray {
        border-color: #dde2e5;
        background: #e9ecef;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }

    .form-select {
        background-position: left 0.75rem center;
    }

    .input-group-lg > .form-select, .input-group-sm > .form-select {
        padding-left: 3rem;
        padding-right: 0.5rem;
    }

    .form-select {
        &.form-select-sm {
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
            padding-left: inherit;
            padding-right: 0.5rem;
            font-size: 0.875rem;
        }
    }
}
