.fade-out {
    animation: fadeOut 10s;
}

@keyframes fadeOut {
    1% {
        opacity: 1;
        display: block;
        visibility: visible;
    }

    50% {
        opacity: 1;
        display: block;
        visibility: visible;
    }

    75% {
        opacity: .9;
    }

    100% {
        opacity: 0;
        display: none;
        visibility: hidden;
    }
}