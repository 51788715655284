.card {
    border: 1px solid grey;
    background-color: #f8f9fa;
    padding: 2rem;
}

.card-raised {
    border: none;
    padding: unset;
}

.profile_card__table {
    border: 1.5px solid #e8ebee;
    background-color: #f8f9fa;
    padding: 0.5rem;
    border-radius: 5px;
}