.dd-list {
    width: 100%;
    background: white;
}

.dd-box {
    box-sizing: border-box;
    background: white;
}

.cdk-drag-preview {
    background: white;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
        0 8px 10px 1px rgba(0, 0, 0, 0.14),
        0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.dd-box:last-child {
    border: none;
}

.dd-list.cdk-drop-list-dragging .dd-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview .hidden-preview {
    opacity: 0;
}

.dragCursor {
    cursor: move;
}

.dd-list.cdk-drop-list-dragging tr:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.dd-custom-placeholder {
    background: #ccc;
    border: dotted 3px #999;
    min-height: 60px;
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
