@import '../variables';

.form-check-input:focus {
    box-shadow: unset !important;
}

.form-control {
    &.is-invalid,
    &.ng-invalid.ng-touched {
        background-image: none !important; //hide default error icon
        border: 1px solid #c92424;
    }
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.form-control.ng-invalid:focus {
    box-shadow: unset !important;
}

.form-control:focus {
    box-shadow: none !important;
}

.form-control {
    border-radius: 10px;
}


.form-container {
    padding: 40px 20px;
    border: 1px solid $accent;
    border-radius: 30px;
}

.input-group {
    &.is-invalid {
        input.form-control,
        .input-group-text {
            border: 1px solid #c92424;
        }
    }
}

.is-invalid,
.ng-invalid.ng-touched {
    &>.multiselect-dropdown .dropdown-btn {
        border: 1px solid #c92424;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

// form actions
.form-actions.gap-20 {
    gap: 20px;

    .form-action {
        font-size: 20px;
        width: min(100%, 11em);
        transition: all .3s ease;
        border-radius: 10px;
        padding-block: .25em;

        &:not(.btn-outline-dark) {
            border: 0;
        }

        &:hover {
            filter: brightness(85%);
        }

        &:disabled {
            opacity: 50%;
            filter: brightness(100%);
        }
    }
}