@font-face {
  font-family: 'Dubai-Bold';
  src: url('../../fonts/Dubai-Bold.eot?#iefix') format('embedded-opentype'), url('../../fonts/Dubai-Bold.woff') format('woff'), url('../../fonts/Dubai-Bold.ttf') format('truetype'), url('../../fonts/Dubai-Bold.woff2#Dubai-Bold') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Dubai-Medium';
  src: url('../../fonts/Dubai-Medium.eot?#iefix') format('embedded-opentype'), url('../../fonts/Dubai-Medium.woff') format('woff'), url('../../fonts/Dubai-Medium.ttf') format('truetype'), url('../../fonts/Dubai-Medium.woff2#Dubai-Medium') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Dubai-Regular';
  src: url('../../fonts/Dubai-Regular.eot?#iefix') format('embedded-opentype'), url('../../fonts/Dubai-Regular.woff') format('woff'), url('../../fonts/Dubai-Regular.ttf') format('truetype'), url('../../fonts/Dubai-Regular.woff2#Dubai-Regular') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Dubai-Light';
  src: url('../../fonts/Dubai-Light.eot?#iefix') format('embedded-opentype'), url('../../fonts/Dubai-Light.woff') format('woff'), url('../../fonts/Dubai-Light.ttf') format('truetype'), url('../../fonts/Dubai-Light.woff2#Dubai-Light') format('woff2');
  font-weight: normal;
  font-style: normal;
}
.font-dubai-bold {
  font-family: 'Dubai-Bold', Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
}
.font-dubai-medium {
  font-family: 'Dubai-Medium', Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
}
.font-dubai-regular {
  font-family: 'Dubai-Regular', Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
}
.font-dubai-light {
  font-family: 'Dubai-Light', Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
}
