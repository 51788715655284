.skeleton {
    &-line {
        height: initial;
        background: rgba(155, 155, 155, 0.2);
        background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(155, 155, 155, 0.2)), color-stop(18%, rgba(155, 155, 155, 0.3)), color-stop(33%, rgba(155, 155, 155, 0.2)));
        background: linear-gradient(to right, rgba(155, 155, 155, 0.2) 8%, rgba(155, 155, 155, 0.3) 18%, rgba(155, 155, 155, 0.2) 33%);
        background-size: 800px 100px;
        animation: wave-lines 2s infinite ease-out;
    }
}

@keyframes wave-lines {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}