@import "../variables";

$status-colors: (
    paid: $paid-color,
    pending: $pending-color,
    approved: $green,
    ready_to_ship: $green,
    rejected: $rejected-color,
    transferred: $transferred-color,
    shipped: $transferred-color,
    delivered: $delivered-color,
    ongoing: $ongoing-color,
    completed: $success,
    cancelled: $danger,

    PAID: $paid-color,
    PENDING: $pending-color,
    APPROVED: $green,
    READY_TO_SHIP: $green,
    REJECTED: $rejected-color,
    TRANSFERRED: $transferred-color,
    SHIPPED: $transferred-color,
    DELIVERED: $delivered-color,
    ONGOING: $ongoing-color,
    COMPLETED: $success,
    CANCELLED: $danger
);

@mixin text-variant($color) {
    color: $color !important;
}

@each $color, $value in $status-colors {
    .text-#{$color} {
        @include text-variant($value);
    }
}

// border
@mixin border-variant($color) {
    border: 1px solid $color !important;
    color: $color !important;
}

@each $color, $value in $status-colors {
    .border-#{$color} {
        @include border-variant($value);
    }
}