$primary: #544d7e;
$secondary: #ffd740;
$accent:#D9D9D9;
$success: #0b9b23;
$red: #de0e0e;
$danger: $red;
$warning: orange;
$info: #0e76de;
$light: #f5f5f5;
$dark: #070707;
$green: #93de3c;
$pink: #f1abc9;
$gray: #b3b3b3;
$dark-gray:#7F7F7F;
$white: #ffffff;
$black: #000000;

$paid-color: #e47cd6;
$pending-color: #999999;
$approved-color: $green;
$rejected-color: $danger;
$delivered-color: #a2b5ff;
$transferred-color: #107C10;
$ongoing-color: #e88e46;

$checkin-color: #107C10;
$checkout-color: $info;
$absent-color: $danger;
$late-color: #e4f394;
$early-color: #e7baf0;
$export-color: $primary;

$online-order: #e47cd6;
$walkin-order: #c92424;
$return-color: #a2b5ff;
$transfer-color: $gray;
$fee-color: $black;

$vat: $black;
$fund: #fb8989;
$refund: $gray;

$WALK_IN_FOOD_ORDER: $walkin-order;
$APP_FOOD_ORDER: $online-order;
$RETURN_ORDER: $return-color;
$COUPON_PURCHASE: #13c3d0;
$TRANSFER_TO_CANTEEN: $gray;
$WAKI_COUPON_FEE: #0b9b3f;
$TRANSFER_FEE: #f08a5d;
$VAT: $black;
$CARD_ORDER: #7ce489;
$RETURN_CARD_ORDER: #18a362;
$REVERSE_FROM_DEPENDENT: #b3b3b3;
$DIGITAL_CARD_ORDER: #f78930;
$DIRECT_PAYMENTS: #303F5A;

$border-color: #ced4da;

$theme-colors: (
    primary: $primary,
    secondary: $secondary,
    success: $success,
    danger: $danger,
    warning: $warning,
    info: $info,
    light: $light,
    dark: $dark,
);

.bg {

    &-PENDING,
    &-pending,
    &-PAID,
    &-paid {
        background: $pending-color;
        font-weight: 500 !important;
    }

    &-APPROVED,
    &-approved,
    &-READY_TO_SHIP,
    &-ready_to_ship {
        background: $approved-color;
        font-weight: 500 !important;
    }

    &-REJECTED,
    &-rejected {
        background: $rejected-color;
        font-weight: 500 !important;
    }

    &-TRANSFERRED,
    &-transferred,
    &-SHIPPED,
    &-shipped {
        background: $transferred-color;
        font-weight: 500 !important;
    }

    &-DELIVERED,
    &-delivered {
        background: $delivered-color;
        font-weight: 500 !important;
    }

    &-app-order {
        background: $online-order;
    }

    &-walkin-order {
        background: $walkin-order;
    }

    &-RETURN_ORDER,
    &-return-order {
        background: $return-color;
        color: $dark !important;
    }

    &-RETURNED,
    &-returned {
        background: $RETURN_CARD_ORDER;
        font-weight: 500 !important;
    }
}

.color {
    &-warning {
        color: $warning !important;
    }

    &-danger {
        color: $danger !important;
    }

    &-black {
        color: $black !important;
    }

    &-approved,
    &-ready_to_ship {
        color: $approved-color !important;
    }

    &-rejected {
        color: $rejected-color !important;
    }

    &-transferred,
    &-shipped {
        color: $transferred-color !important;
    }

    &-delivered {
        color: $delivered-color !important;
    }

    &-returned {
        color: #18a362 !important;
    }
    

    &-invited {
        color: #FC9403 !important;
    }

    &-accepted {
        color: #107C10 !important;
    }

    &-removed {
        color: #C92424 !important;
    }
}

.btn-round {

    &-info,
    &-warning,
    &-light {
        border-radius: 100% !important;
    }

    &-info {
        color: #0e76de !important;
        padding: 0 0.5rem !important;

        &:hover {
            color: #0f5ac7;
        }
    }

    &-warning {
        background: rgb(255, 182, 48) !important;
        color: $primary !important;

        &:hover {
            background: $warning !important;
            color: #000 !important;
        }
    }

    &-light {
        background: $light !important;

        &:hover {
            background: #dee2e6 !important;

            &.check {
                color: $success;
                font-weight: bold;
            }

            &.close {
                color: $danger;
                font-weight: bold;
            }
        }
    }
}

.btn-outline {
    &-primary,
    &-secondary,
    &-success,
    &-danger,
    &-warning,
    &-info,
    &-dark,
    &-default {
        // common style
        border-radius: 10px;
        transition: all 0.3s ease;
    }

    &-default {
        border-color: $accent;
        color: $primary;

        &:hover {
            background-color:$accent;
            color: $primary;
        }
    }
}

.badge {
    font-weight: 500 !important;
}

.border-radius {
    border-radius: 10px !important;
}

.border-radius-0 {
    border-radius: 0 !important;
}