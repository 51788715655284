//
// Floating-label
//
.form-floating {
    position: relative;

    >.form-control,
    >.form-select {
        height: $form-floating-height;
        line-height: $form-floating-line-height;
    }

    >label {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%; // allow textareas
        padding: $form-floating-padding-y $form-floating-padding-x;
        pointer-events: none;
        border: $input-border-width solid transparent; // Required for aligning label's text with the input as it affects inner box model
        transform-origin: 0 0;
        @include transition($form-floating-transition);
    }

    // stylelint-disable no-duplicate-selectors
    >.form-control {
        padding: $form-floating-padding-y $form-floating-padding-x;

        &::placeholder {
            color: transparent;
        }

        &:focus,
        &:not(:placeholder-shown) {
            padding-top: $form-floating-input-padding-t;
            padding-bottom: $form-floating-input-padding-b;
        }

        // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
        &:-webkit-autofill {
            padding-top: $form-floating-input-padding-t;
            padding-bottom: $form-floating-input-padding-b;
        }
    }

    >.form-select {
        padding-top: $form-floating-input-padding-t;
        padding-bottom: $form-floating-input-padding-b;
    }

    >.form-control:focus,
    >.form-control:not(:placeholder-shown),
    >.form-select {
        ~label {
            opacity: $form-floating-label-opacity;
            transform: $form-floating-label-transform;
        }
    }

    // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
    >.form-control:-webkit-autofill {
        ~label {
            opacity: $form-floating-label-opacity;
            transform: $form-floating-label-transform;
        }
    }

    // stylelint-enable no-duplicate-selectors
}

//
// Check/radio
//
.form-check {
    display: block;
    min-height: $form-check-min-height;
    padding-left: 0;
    margin-bottom: $form-check-margin-bottom;

    .form-check-input {
        float: left;
        margin-left: $form-check-padding-start * -1;
    }
}

.form-check-right {
    .form-check-input {
        float: right;
        margin-left: $form-check-padding-start * -1;
    }
}

.form-check-input {
    width: $form-check-input-width;
    height: $form-check-input-width;
    margin-top: ($line-height-base - $form-check-input-width) * .5; // line-height minus check height
    vertical-align: top;
    background-color: $form-check-input-bg;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: $form-check-input-border;
    appearance: none;
    color-adjust: exact; // Keep themed appearance for print
    @include transition($form-check-transition);

    &[type="checkbox"] {
        @include border-radius($form-check-input-border-radius);
    }

    &[type="radio"] {
        // stylelint-disable-next-line property-disallowed-list
        border-radius: $form-check-radio-border-radius;
    }

    &:active {
        filter: $form-check-input-active-filter;
    }

    &:focus {
        border-color: $form-check-input-focus-border;
        outline: 0;
        box-shadow: $form-check-input-focus-box-shadow;
    }

    &:checked {
        background-color: $form-check-input-checked-bg-color;
        border-color: $form-check-input-checked-border-color;

        &[type="checkbox"] {
            @if $enable-gradients {
                background-image: escape-svg($form-check-input-checked-bg-image),
                var(--#{$variable-prefix}gradient);
            }

            @else {
                background-image: escape-svg($form-check-input-checked-bg-image);
            }
        }

        &[type="radio"] {
            @if $enable-gradients {
                background-image: escape-svg($form-check-radio-checked-bg-image),
                var(--#{$variable-prefix}gradient);
            }

            @else {
                background-image: escape-svg($form-check-radio-checked-bg-image);
            }
        }
    }

    &[type="checkbox"]:indeterminate {
        background-color: $form-check-input-indeterminate-bg-color;
        border-color: $form-check-input-indeterminate-border-color;

        @if $enable-gradients {
            background-image: escape-svg($form-check-input-indeterminate-bg-image),
            var(--#{$variable-prefix}gradient);
        }

        @else {
            background-image: escape-svg($form-check-input-indeterminate-bg-image);
        }
    }

    &:disabled {
        pointer-events: none;
        filter: none;
        opacity: $form-check-input-disabled-opacity;
    }

    // Use disabled attribute in addition of :disabled pseudo-class
    // See: https://github.com/twbs/bootstrap/issues/28247
    &[disabled],
    &:disabled {
        ~.form-check-label {
            opacity: $form-check-label-disabled-opacity;
        }
    }
}

.form-check-label {
    color: $form-check-label-color;
    cursor: $form-check-label-cursor;
}

//
// Switch
//

.form-switch {
    .form-switch-label {
        vertical-align: sub;
    }

    .form-check-input {
        width: 3em;
        height: 1.5em;
        margin-left: $form-switch-padding-start * -1;
        background-image: escape-svg($form-switch-bg-image);
        background-position: left center;
        @include border-radius($form-switch-border-radius);
        @include transition($form-switch-transition);

        &:focus {
            background-image: escape-svg($form-switch-focus-bg-image);
        }

        &:checked {
            background-position: $form-switch-checked-bg-position;

            @if $enable-gradients {
                background-image: escape-svg($form-switch-checked-bg-image),
                var(--#{$variable-prefix}gradient);
            }

            @else {
                background-image: escape-svg($form-switch-checked-bg-image);
            }
        }
    }
}

.form-check-inline {
    display: inline-block;
    margin-right: $form-check-inline-margin-end;
}

.btn-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;

    &[disabled],
    &:disabled {
        +.btn {
            pointer-events: none;
            filter: none;
            opacity: $form-check-btn-check-disabled-opacity;
        }
    }
}

// Form validation
//
// Provide feedback to users when form field values are valid or invalid. Works
// primarily for client-side validation via scoped `:invalid` and `:valid`
// pseudo-classes but also includes `.is-invalid` and `.is-valid` classes for
// server-side validation.

// scss-docs-start form-validation-states-loop
@each $state, $data in $form-validation-states {
    @include form-validation-state($state, $data...);
}
// scss-docs-end form-validation-states-loop
