.mr-0 {
    margin-right: 0 !important;
}

.mr-1 {
    margin-right: ($spacer * .25) !important;
}

.mr-2 {
    margin-right: ($spacer * .5) !important;
}

.mr-3 {
    margin-right: $spacer !important;
}

.mr-4 {
    margin-right: ($spacer * 1.5) !important;
}

.mr-5 {
    margin-right: ($spacer * 3) !important;
}

.mr-auto {
    margin-right: auto !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.ml-1 {
    margin-left: ($spacer * .25) !important;
}

.ml-2 {
    margin-left: ($spacer * .5) !important;
}

.ml-3 {
    margin-left: $spacer !important;
}

.ml-4 {
    margin-left: ($spacer * 1.5) !important;
}

.ml-5 {
    margin-left: ($spacer * 3) !important;
}

.ml-auto {
    margin-left: auto !important;
}

.opacity-0 {
    opacity: 0 !important;
}

.opacity-25 {
    opacity: .25 !important;
}

.opacity-50 {
    opacity: .5 !important;
}

.opacity-75 {
    opacity: .75 !important;
}

.opacity-100 {
    opacity: 1 !important;
}

@media (min-width: 576px) {
    .opacity-sm-0 {
        opacity: 0 !important;
    }

    .opacity-sm-25 {
        opacity: .25 !important;
    }

    .opacity-sm-50 {
        opacity: .5 !important;
    }

    .opacity-sm-75 {
        opacity: .75 !important;
    }

    .opacity-sm-100 {
        opacity: 1 !important;
    }
}

@media (min-width: 768px) {
    .opacity-md-0 {
        opacity: 0 !important;
    }

    .opacity-md-25 {
        opacity: .25 !important;
    }

    .opacity-md-50 {
        opacity: .5 !important;
    }

    .opacity-md-75 {
        opacity: .75 !important;
    }

    .opacity-md-100 {
        opacity: 1 !important;
    }
}

@media (min-width: 992px) {
    .opacity-lg-0 {
        opacity: 0 !important;
    }

    .opacity-lg-25 {
        opacity: .25 !important;
    }

    .opacity-lg-50 {
        opacity: .5 !important;
    }

    .opacity-lg-75 {
        opacity: .75 !important;
    }

    .opacity-lg-100 {
        opacity: 1 !important;
    }
}

@media (min-width: 1200px) {
    .opacity-xl-0 {
        opacity: 0 !important;
    }

    .opacity-xl-25 {
        opacity: .25 !important;
    }

    .opacity-xl-50 {
        opacity: .5 !important;
    }

    .opacity-xl-75 {
        opacity: .75 !important;
    }

    .opacity-xl-100 {
        opacity: 1 !important;
    }
}

@media (min-width: 1400px) {
    .opacity-xxl-0 {
        opacity: 0 !important;
    }

    .opacity-xxl-25 {
        opacity: .25 !important;
    }

    .opacity-xxl-50 {
        opacity: .5 !important;
    }

    .opacity-xxl-75 {
        opacity: .75 !important;
    }

    .opacity-xxl-100 {
        opacity: 1 !important;
    }
}

.m-gap {
    grid-gap: 3rem !important;
    gap: calc(100vw - 15rem) !important;
}

// Alignment
.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

.text-justify {
    text-align: justify !important;
}

// Font-size
.text-hint {
    font-size: 15px !important;
}

.text-small {
    font-size: 11px !important;
}

.text-italic {
    font-style: italic !important;
}

// Font-weight
.f-500 {
    font-weight: 500 !important;
}

.f-600 {
    font-weight: 600 !important;
}

.f-bold {
    font-weight: bold !important;
}

// Vertical-align
.vertical-baseline {
    vertical-align: baseline !important;
}

.vertical-top {
    vertical-align: top !important;
}

.vertical-sub {
    vertical-align: sub !important;
}