.mat-bottom-sheet-container-xlarge,
.mat-bottom-sheet-container-large,
.mat-bottom-sheet-container-medium {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}

.bottom-sheet-content {
    margin: 0 0 1rem;

    .table:has(.table-borderless) {}

    .tableContainer .table {
        thead {
            tr {
                font-family: 'Dubai-Medium', Helvetica, sans-serif;
                font-weight: normal;
                font-style: normal;
            }
        }

        td {
            padding-top: 0.5rem;
        }
    }

    p {
        font-size: 20px;
        color: #A9A5BE;
    }
}