// Bootstrap
@import './bootstrap/styles.scss';

// Variables
@import "./variables";

caption {
  color: $primary !important;
  font-size: 1rem;
}

.is-hidden,
.btn-is-hidden {
  pointer-events: none !important;
  opacity: 0 !important;
}

.pointer-event-none {
  pointer-events: none !important;
}

// Data card for mobile view
.mobileCard {
  $cardRadius: 10px;

  display: grid;
  gap: .25em;

  border: 1px solid $accent;
  border-radius: $cardRadius;

  margin-bottom: 1em;

  .mobileCardData {
    display: flex;
    justify-content: space-between;
    align-items: center;

    gap: .25em;

    padding: .375em 1.25em;

    font-weight: 400;

    color: $dark-gray;

    &:not(:last-child) {
      border-bottom: 1px solid $accent;
    }
  }

  .mobileCardHeader {
    background-color: $accent;
    color: $primary;

    border-radius: 8px;

    padding: .25em .75em;

    font-weight: 600;
  }
  .mobileCardHeaderGrid {
    display: grid;
    gap: 1em;
    grid-auto-flow: column;
    grid-template-columns: repeat(3,1fr);
  }
}


  .editButton {
    background: none;
    border: none;

    padding: 0;
    margin: 0;

    color: $primary;
  }

.mobileCardBorder {
    &__WALK_IN_FOOD_ORDER, &__MERCHANT_WALK_IN_ORDER {
        border-color: $WALK_IN_FOOD_ORDER;
    }

    &__APP_FOOD_ORDER {
        border-color: $APP_FOOD_ORDER;
    }

    &__RETURN_ORDER, &__MERCHANT_RETURN_ORDER {
        border-color: $RETURN_ORDER;
    }

    &__COUPON_PURCHASE {
        border-color: $COUPON_PURCHASE;
    }

    &__TRANSFER_TO_CANTEEN, &__TRANSFER_TO_MERCHANT {
        border-color: $TRANSFER_TO_CANTEEN;
    }

    &__WAKI_COUPON_FEE {
        border-color: $WAKI_COUPON_FEE;
    }

    &__TRANSFER_FEE, &__MERCHANT_TRANSFER_FEE {
        border-color: $TRANSFER_FEE;
    }

    &__VAT {
        border-color: $VAT;
    }
}

// Data tables for desktop view
// Wrap the table class with tableContainer class to the make the changes work
.tableContainer {
  $radius: 15px;
  overflow-x:auto;

  @media(min-width:1240px) {
    border: 1px solid $accent;
    border-radius: 10px;
  }

  .table {
    border-color: $accent;
    max-height: 50px;
    overflow-y: auto;

    margin-bottom: 0;

    tbody {
      :last-child td {
        border-bottom-width: 0px;
      }
    }

    th {
      color: $primary;
    }

    tbody tr:last-child td {
      border-bottom: none;
    }

    td {
      color: $dark-gray;
      padding-top: 1em;
      vertical-align: middle !important;
      transition: all .3s ease;

      a, .name, .hyperButton{
        color: $primary !important;
        font-weight: normal !important;
        cursor:  pointer !important;
        text-decoration:none !important;

      }
    }
  }

  .table-hover tbody tr:hover td,
  .table-hover tbody tr:hover th {
    color: $primary;
    transition: all .3s ease;


    a, .name, .hyperButton{
      text-decoration: underline !important;
    }
  }

  &:has(>.table-borderless) {
    background: #DFDEE6;
  }
}

// Expandable cards
$radius: 10px;

.expand {

  border: 1px solid $accent;
  border-radius: $radius;

  .form-control {
    border-radius: $radius;
    color: $primary;

    &:focus,
    &:hover:not(:disabled) {
      border-color: $primary;
    }

    &.is-invalid:focus,
    &.ng-invalid.ng-touched:focus,
    &.is-invalid:hover,
    &.ng-invalid.ng-touched:hover {
      border-color: $danger;
    }
  }


  .form-group {
    margin-top: 1rem;
  }

  label {
    color: #666666;
  }

  .infoHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: $accent;
    border-radius: 8px;

    padding: .25em .75em;

    font-weight: 600;
    color: $primary;

    cursor: pointer;

    .expandIcon {
      transform: rotate(0deg);
      transition: transform .5s ease;
    }

    .openExpand {
      transform: rotate(-180deg);
      transition: transform .5s ease;
    }

  }

  .infoHeader[data-toggle="no-collapse"] {
    cursor: default;
  }

  .inputs {
    padding: 0 .75em 1em .75em;

    .expandContainer{
      display: grid;
      gap:1em;
  
      grid-template-columns: repeat(auto-fit,minmax(15em, 1fr));
    }

    .deleteButton{
      background-color: $red;
      color: white;
  
      &:hover:not(:disabled), &:focus{
          color: $primary;
      }
    }
  }

}

.inputContainer {
  display: flex;
  flex-direction: column;

  >* {
    flex-basis: 100%;
    flex-grow: 1;
  }

  @media(min-width: 620px) {
    flex-direction: row;
    gap: 1.5em;
  }
}

.inputGroup {
  display: flex;

  border: 1px solid $accent;
  border-radius: $radius;

  transition: all .3s ease;

  &:has(>.form-control:read-only),
  &:has(>.form-control:disabled) {
    background-color: $accent;
  }

  .inputGroupAddon {
    background: $accent;
    padding: 0.5em 1em;

    border-radius: 7px;
    border: 1px solid $accent;
  }

  .addon {
    border: 0;

    &::placeholder {
      text-transform: lowercase;
    }
  }

  &:focus-within,
  &:hover {
    border-color: $primary;
  }
}

.subExpand {
  border-color: $primary;

    margin-top: 1em;

  .infoHeader {
    background-color: $primary;
    color: white;
  }

  .inputContainer{
    padding: 0 .75em .75em .75em;

    gap: .75em;
    
    .form-group{
        margin: 0;
    }
}
}

.subExpanedContainer{
  display: flex;
  flex-direction: column;

  .data:not(:last-child){
    border-bottom: .5px solid $accent;
  }

}
.dataButton{
    width: 31px;
    height: 31px;

    display: flex;

    justify-content: center;
    align-items: center;

    background-color: #FED740;
    border: none;
    color: $primary;

    border-radius: 10px;

    transition: all .3s ease;

    padding: .25em;

    &:hover:not(:disabled) , &:focus{
        background-color: $accent;
    }
    &:disabled{
      opacity: .4;
  }
}

.data{
  display: flex;
  justify-content: space-between;

  align-items: center;

  padding:.75em;

  color: #666666;
}

.mat-tab-label-active{
  color: $primary;
  font-weight: 600 !important;
  opacity: 1 !important;
}

.mat-ink-bar{
  background-color: $primary !important
}

// Logs
.logsContainer{
  display: flex;
  flex-direction: column;
  gap: 1em;

}
.expand-disabled-bg {
  background-color: $accent;

  .infoHeader{
      border-color: #A9A5BE;
  }

  .data{
      padding: .375em .75em;
  }

  .inputs{
      padding-block: .5em;
  }
}

.logs{
  height: fit-content;
  .infoHeader{
      border-radius: 0;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      border-bottom: 1px solid $accent;
  }

  .gridHeader{
      display: grid;
      gap: .25em;
      grid-template-columns: 1fr 5fr 1fr;
  }

  .data{
      gap:.25em;
  }
}

// table, ul
.no-list-items {
  display: flex;
  flex-direction: column;
  text-align: center;

  @media screen and (min-width: 1240px) and (min-height: 720px) {
    font-size: 30px;
  }

  label {
    color: #a9a5be;
    margin-top: -5px;
  }

  img {
    width: 291px;
    height: 273px;

    &.no-agents {
      width: 282px;
      height: 264px;
      padding: 3rem;
      margin-right: -1rem;
    }
  }
}

// Mobile filters

.filterButtons{
  background-color: white;
  border: 1px solid $accent;
  color: $primary;

  border-radius: 10px;

  transition: all .3s ease;

  &:hover{
      background-color: $accent;
  }

  &.active{
      color: white;
      background-color: $primary;
  }

}

.dropdown{
  .dropdown-toggle{
    &::after{
        border: 0;
        width: 14px;
        height: 10px;
        background-image: url('../images/ng-select/expand.svg');
        background-repeat: no-repeat;
        vertical-align: baseline;
  
        transition: all .3s ease;
      }
  }

  [aria-expanded="true"]{
    &.dropdown-toggle{
      &::after{
        background-image: url('../images/ng-select/collapse.svg');
      }
    }
  }

}

.multiselect-dropdown-checkboxes{
  display: flex;
  align-items: center;
  gap: 1em;

  font-weight: 600;
  color: $primary;

  input[type="checkbox"]{
      background-color: $accent;
      border: none;
      cursor: pointer;

      margin-top: -.125em;
      flex-shrink: 0;
  
      &:checked {
          background-color: $primary;
      }
  }
}