.waki__card {
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    display: block;
    position: relative;
    padding: 0;
    border: 1px solid rgba(84, 77, 126, 0.125);
    border-radius: 0.2rem 0.2rem 3rem 0.2rem;
    margin: 0.5rem;
    font-family: 'Dubai-Regular';

    p,
    a,
    div {
        font-family: 'Dubai-Medium';
    }
}

.waki__card>:first-child,
.waki__card__content>:first-child {
    margin-top: 0;
}

.waki__card__title__group {
    display: flex;
    justify-content: space-between;
    background: #544d7e;
    color: #fff;
    padding: 12px 18px 0;
    border-radius: .2rem .2rem 0 0;
    min-height: 50px !important;
    font-family: 'Dubai-Medium';
}

.waki__card__title {
    display: block;
    margin-bottom: 8px;
    font-size: 17px;
    font-family: 'Dubai-Regular' !important;
}

.waki__card__subtitle {
    color: #0000008a;
    font-size: 14px;
    font-family: 'Dubai-Medium';
}

.waki__card__title:not(:first-child),
.waki__card__subtitle:not(:first-child) {
    margin-top: -4px;
}

.waki__card:not([class*=mat-elevation-z]) {
    box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
}

.waki__card__content {
    display: block;
    margin-bottom: 16px;

    .list-group-item {
        background: transparent !important;
        padding: 0.5rem 2rem;
    }
}

.waki__card__actions {
    display: inline-flex !important;
    margin-left: -8px;
    margin-right: -8px;

    button:not(:first-child) {
        margin-left: 3px;
    }
}

.waki__card__actions_system_page_en {
    display: inline-flex !important;
    margin: auto;
    margin-right: 8px;

    button:not(:first-child) {
        margin-left: 3px;
    }
}

.waki__card__actions_system_page_ar {
    display: inline-flex !important;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 8px;

    button:not(:first-child) {
        margin-left: 3px;
    }
}

.waki__card>:last-child:not(.waki__card__footer),
.waki__card__content>:last-child:not(.waki__card__footer) {
    margin-bottom: 0;
}

.waki__card>.waki__card__actions:last-child {
    margin-bottom: -8px;
    padding-bottom: 0;
}

.waki__card__actions,
.waki__card__subtitle,
.waki__card__content {
    display: block;
    margin-bottom: 16px;
}

.waki__card__head_only {
    &.waki__card {
        border-radius: 0.3rem !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        border: 1px solid #544d7e !important;
    }

    .waki__card__title__group {
        background: #544d7e0f;
        color: #544d7e;
    }
}

.waki__btn_group {
    .btn-outline-primary {
        border: 1px solid #ced4da !important;

        &:hover {
            color: #544d7e;
            background-color: #ff991fa6;
        }
    }

    &.required {
        .btn-outline-primary {
            border: 1px solid #de0e0e7a !important;

            &:hover {
                color: #544d7e;
                background-color: #ff991fa6;
            }
        }
    }

    .btn-check:checked+.btn-outline-primary {
        background-color: #ff991f !important;
        border-color: #ff991f !important;

        &:hover {
            color: #ffffff;
        }
    }

    .border_start {
        border-top-left-radius: 0.25rem !important;
        border-bottom-left-radius: 0.25rem !important;
    }

    .border_end {
        border-top-right-radius: 0.25rem !important;
        border-bottom-right-radius: 0.25rem !important;
    }
}
